import logo from './logo.svg';
import './App.css';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import React, { Component }  from 'react';
const cheerio = require('cheerio');

function compare(a, b) {
  if (a.points < b.points) {
    return 1;
  } else if (a.points > b.points) {
    return -1
  } else {
    return 0
  }

}

var myTeam = "Eagles";
var oppTeam = "Falcons";
var gameURL = "https://www.espn.com/nfl/boxscore/_/gameId/401333570"
var users = [
  {"id":"eaglesdiehard", guess: {"score": {"Eagles": 24, "Falcons": 20}, passing: {"name": "Hurts", yards: 300},rushing: {"name": "Sanders", yards: 90},receiving: {"name": "Smith", yards: 80}}, points: 70},
];
var results = {

};
function computeScore() {
  /*
  fetch(gameURL).then(function (response) {
    // The API call was successful!
    return response.text();
  }).then(function (html) {
    // This is the HTML from our response as a text string
    const $ = cheerio.load(html);
    console.log($('table[id=linescore]').html());
  }).catch(function (err) {
    // There was an error
    console.warn('Something went wrong.', err);
  });
  */

  for (let i=0; i<users.length; i++){
    let user = users[i];
    if (user.guess) {

      let points = 0;
      if (user.guess.score) {
        let s = "";
        if (user.guess.score[myTeam]>=user.guess.score[oppTeam]) {
          s = myTeam + " " +user.guess.score[myTeam]+'-'+user.guess.score[oppTeam]
        } else {
          s = oppTeam + " " +user.guess.score[oppTeam]+'-'+user.guess.score[myTeam]
        }
        if (results.score) {

          let correct = (results.score[myTeam]>results.score[oppTeam] && user.guess.score[myTeam]>user.guess.score[oppTeam] ) ||
          (results.score[myTeam]<results.score[oppTeam] && user.guess.score[myTeam]<user.guess.score[oppTeam] ) ||
          (results.score[myTeam]==results.score[oppTeam] && user.guess.score[myTeam]==user.guess.score[oppTeam] )
          if (correct) {
            points += 25;
            let tot = Math.abs(results.score[myTeam]-user.guess.score[myTeam]) + Math.abs(results.score[oppTeam]-user.guess.score[oppTeam]);
            if (tot<25) {
              points += (25-tot);
            }
          }
        }
        user.score = s;
      }
      for (let cat=0; cat<3; cat++) {
        let catname =["passing","rushing","receiving"][cat];
        if (user.guess[catname]) {
          let s = "";
          s = user.guess[catname].name + " " + user.guess[catname].yards;
          user[catname] = s;
          if (results[catname]) {
            let correct = false;
            for (let j=0; j<results[catname].name.length; j++) {
              if (user.guess[catname].name == results[catname].name[j]) {
                correct = true;
                break;
              }
            }
            if (correct) {
              points += 25;
              let tot = Math.abs(user.guess[catname].yards-results[catname].yards);
              if (tot<25) {
                points += (25-tot);
              }
            }
          }
        }
      }

      user.points = points;
    } else {
      user.points = 0;
    }
    if (!results.score) {
      user.points = "";
    }
    users[i]=user;
  }
  users.sort(compare);
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {users: users};
  }
  componentDidMount() {
    computeScore();
    this.setState({users: users});
  }
  render() {
  return (
    <div className="App">
      <div className="App-header">
        <div style={{width: '100%'}}>
          <div style={{width: '300px', marginLeft: 'auto', marginRight: 'auto'}}>
        <TwitterTweetEmbed
  tweetId={'1434246047400796161'}
/></div>
</div>
<div style={{fontSize: '18px',marginLeft: '10px', marginRight: '10px',marginBottom: '50px'}}>Scoring: 25pts for correct winner or leader, up to 25pts for accuracy of score or yardage</div>
<div className="add100">
<div style={{marginLeft: 'auto', marginRight: 'auto'}}>
<table style={{borderWidth: '1px', borderStyle: 'solid 1px',borderColor: 'white'}}>
  <thead><tr><td>User</td><td>Points</td><td>Score</td><td>Passing</td><td>Rushing</td><td>Receiving</td></tr></thead>
<tbody>
{this.state.users.map((user) => (
        <tr key={user.id}><td>{user.id}</td>
        <td>{user.points}</td>
        <td>{user.score}</td>
        <td>{user.passing}</td>
        <td>{user.rushing}</td>
        <td>{user.receiving}</td>
        </tr>
      ))}
</tbody>
</table>
</div></div>
      </div>
    </div>
  )
}}

export default App;
